var axios = require('axios')

var updateAssignTo = async function (params) {
    try {
        var resp = await axios.put(process.env.VUE_APP_API_URL + '/contact/assign', params);

        return resp.data;
    } catch (error) {
        throw error
    }       
}

exports.updateAssignTo = updateAssignTo;